<admiralty-header *ngIf="headerItems.length > 0"
                  alt="UK Hydrographic Office"
                  [ngClass]="(isValidUser$ | async) || (isAuthenticated$ | async) ? '' : 'header'"
                  (titledClicked)="dashboardNavigate()"
                  [logoImgUrl]="header.logoImgUrl"
                  [logoLinkUrl]="header.logoLinkUrl"
                  [logoAltText]="header.logoAltText"
                  [headerTitle]="header.title">
  <ng-container *ngIf="getIsAuthedInternalUser$ | async">
    <ng-container *ngFor="let item of headerItems">
      <admiralty-header-menu-item [hidden]="!(isValidUser$ | async)"
                                  [menuTitle]="item.title"
                                  (menuItemClick)="navigate(item.routerLink)"
                                  [slot]="'items'"></admiralty-header-menu-item>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="getIsAuthedExternalUser$ | async">
    <ng-container *ngFor="let item of headerItems">
      <admiralty-header-menu-item [hidden]="!(isValidUser$ | async) && (isUduUser$ | async)"
                                  [menuTitle]="item.title"
                                  (menuItemClick)="navigate(item.routerLink)"
                                  [slot]="'items'"></admiralty-header-menu-item>
    </ng-container>
  </ng-container>
  <admiralty-header-profile *ngIf="isAuthenticated$ | async"
                            [isSignedIn]="(isAuthenticated$ | async)"
                            id="profileButton"
                            (yourAccountClicked)="onAccountClicked($event)"
                            (signOutClicked)="logout($event)"
                            [signedInText]="(fullname | async)"
                            class="profile"
                            slot="profile"></admiralty-header-profile>
</admiralty-header>
<main class="is-12-fullhd is-marginless">
  <div [ngClass]="isLoggedIn()? 'mt-2' : ''">
    <admiralty-phase-banner phase="beta" class="pt-2" [ngClass]="!isLoggedIn()? 'banner' : ''" [link]="sendEmail()">
    </admiralty-phase-banner>
  </div>
  <router-outlet></router-outlet>
</main>
<admiralty-footer imageSrc="../assets/UKHO_stacked_logo.svg">
  <ng-container *ngFor="let item of footerItems">
    <admiralty-link *ngIf="item.href !== null else routerNavigate" [href]="item.href" [newTab]="item.newTab">{{ item.title }}</admiralty-link>
    <ng-template #routerNavigate>
      <admiralty-link [newTab]="item.newTab" (click)="navigate(item.routerLink)">{{ item.title }}</admiralty-link>
    </ng-template>
  </ng-container>
</admiralty-footer>
